import SVGPathfinder from "./modules/svg-pathfinder";
import RegistrationForm from "./modules/registrationForm";
import PasswordResetForm from "./modules/passwordReset";



if(document.querySelector("#passwordreset-form")) {
  new PasswordResetForm();
}

if (document.querySelector("#registration-form")) {
  new RegistrationForm();
}

if (document.querySelector(".svg-container")) {
  let siteId = document.getElementById("siteId").value;

  Promise.all([
    fetch("/api/getNodesAtSite/" + siteId).then((nodes) => nodes.json()),
    fetch("/api/getFloors/" + siteId).then((floors) => floors.json()),
  ])
    .then((data) => {
      let nodes = data[0];
      let floors = data[1];
      nodes = nodes.filter((node) =>
        floors.map((floor) => String(floor._id)).includes(node.FloorId)
      );
      new SVGPathfinder(nodes, floors);
    })
    .catch((err) => {
      console.log(err);
    });
}
