import SVGMap from "./svg-map";

export default class SVGPathfinder {
  constructor(nodes, floors) {
    this.map = new SVGMap(nodes, floors);
    this.isIOS =
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/);

    //pageload vars
    this.compassOffsetDegrees = document.getElementById(
      "compassOffsetDegrees"
    ).value;

    //manage state
    this.currentPathNodes = [];
    this.currentPathDirections = "";
    this.currentFromIndicator;
    this.currentToIndicator;
    this.compass;

    //svg
    this.svgdiv = document.getElementById("svgdiv");
    this.tapedTwice = false;

    //map controls
    this.directionsText = document.getElementById("directionsText");
    this.blankDirectionsText = this.directionsText.innerHTML;

    //quick pills
    this.restroomQuickPill = document.getElementById("restroomQuickPill");
    this.exitQuickPill = document.getElementById("exitQuickPill");
    this.diningQuickPill = document.getElementById("diningQuickPill");
    this.helpQuickPill = document.getElementById("helpQuickPill");

    //path controls
    this.fromDropdown = document.getElementById("fromDropdown");
    this.toDropdown = document.getElementById("toDropdown");
    this.avoidStairs = document.getElementById("avoidStairs");

    this.avoidStairsOld = document.getElementById("avoidStairsOld");

    this.shortestPath = document.getElementById("shortestPath");
    this.avoidStairs = document.getElementById("avoidStairs");
    this.avoidElevators = document.getElementById("avoidElevators");

    //control vals
    this.fromValue =
      this.fromDropdown.value == 0 ? "" : this.fromDropdown.value;
    this.toValue;

    this.init();
  }

  // On page load
  init() {
    //listen for svg-map events
    this.map.on(
      "changeFloorUp",
      function (data) {
        this.map.currentFloor = data;
        this.redrawMapElements();
      }.bind(this)
    );
    this.map.on(
      "changeFloorDown",
      function (data) {
        this.map.currentFloor = data;
        this.redrawMapElements();
      }.bind(this)
    );

    //load last selection
    let avoid = this.getCookie("avoidances");

    if (avoid == "1") {
      this.avoidStairs.checked = true;
    } else if (avoid == "2") {
      this.avoidElevators.checked = true;
    } else {
      this.shortestPath.checked = true;
    }

    //on pageload this should only draw the "you are here" indicator, if exists
    this.redrawMapElements();

    //remove event listeners
    this.removeEvents();

    //add event listeners
    this.addEvents();

    //compass components
    //apparently this isn't supported on pageload
    //this.startCompass();

    endLoading();
  }

  // Events
  addEvents() {
    this.addButtonEvents();
  }

  removeEvents() {
    this.removeButtonEvents();
  }

  addButtonEvents() {
    this.fromDropdown.addEventListener(
      "change",
      this.fromDropdownChangeHandler
    );
    this.toDropdown.addEventListener("change", this.toDropdownChangeHandler);
    this.svgdiv.addEventListener("dblclick", this.mouseDblClick);
    this.shortestPath.addEventListener(
      "change",
      this.shortestPathChangeHandler
    );
    this.avoidStairs.addEventListener("change", this.avoidStairsChangeHandler);
    this.avoidElevators.addEventListener(
      "change",
      this.avoidElevatorsChangeHandler
    );

    this.restroomQuickPill.addEventListener(
      "click",
      this.restroomQuickPillClickHandler
    );
    this.exitQuickPill.addEventListener(
      "click",
      this.exitQuickPillClickHandler
    );
    this.diningQuickPill.addEventListener(
      "click",
      this.diningQuickPillClickHandler
    );
    this.helpQuickPill.addEventListener(
      "click",
      this.helpQuickPillClickHandler
    );
  }

  removeButtonEvents() {
    this.fromDropdown.removeEventListener(
      "change",
      this.fromDropdownChangeHandler
    );
    this.toDropdown.removeEventListener("change", this.toDropdownChangeHandler);
    this.svgdiv.removeEventListener("dblclick", this.mouseDblClick);
    this.shortestPath.removeEventListener(
      "change",
      this.shortestPathChangeHandler
    );
    this.avoidStairs.removeEventListener(
      "change",
      this.avoidStairsChangeHandler
    );
    this.avoidElevators.removeEventListener(
      "change",
      this.avoidElevatorsChangeHandler
    );

    this.restroomQuickPill.removeEventListener(
      "click",
      this.restroomQuickPillClickHandler
    );
    this.exitQuickPill.removeEventListener(
      "click",
      this.exitQuickPillClickHandler
    );
    this.diningQuickPill.removeEventListener(
      "click",
      this.diningQuickPillClickHandler
    );
    this.helpQuickPill.removeEventListener(
      "click",
      this.helpQuickPillClickHandler
    );
  }

  //
  // Methods
  //
  mouseDblClick = (e) => {
    var x = e.clientX;
    var y = e.clientY;
    //alert(['dbl click detected X =', x, 'Y =', y].join(' '));
  };

  tapHandler = (e) => {
    //alert('tap entered pathfinder');
    if (!tapedTwice) {
      tapedTwice = true;
      setTimeout(function () {
        tapedTwice = false;
      }, 300);
      return false;
    }

    //event.preventDefault();
    //action on double tap goes below
    var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
    var x = touch.pageX;
    var y = touch.pageY;
    alert(["dbl tap detected X =", x, "Y =", y].join(" "));
  };

  restroomQuickPillClickHandler = (e) => {
    if (this.currentFromIndicator) {
      let closestNodes = this.map.getNodesClosestToPoint(
        this.currentFromIndicator.X,
        this.currentFromIndicator.Y,
        true
      );
      let closestNodesofInterest = this.filterNodeArrayByKeywords(
        closestNodes,
        ["restroom", "washroom"]
      );

      if (closestNodesofInterest[0]) {
        document
          .querySelectorAll(
            '[data-id="' +
              closestNodesofInterest[0].ID +
              '"][class~="toSearchResult"]'
          )[0]
          .click();
      }
    } else {
      errorToast("Please scan a QR code or select your location from the menu");
    }
  };

  exitQuickPillClickHandler = (e) => {
    if (this.currentFromIndicator) {
      let closestNodes = this.map.getNodesClosestToPoint(
        this.currentFromIndicator.X,
        this.currentFromIndicator.Y,
        true
      );
      let closestNodesofInterest = this.filterNodeArrayByKeywords(
        closestNodes,
        ["exit", "entrance"]
      );

      if (closestNodesofInterest[0]) {
        document
          .querySelectorAll(
            '[data-id="' +
              closestNodesofInterest[0].ID +
              '"][class~="toSearchResult"]'
          )[0]
          .click();
      }
    } else {
      errorToast("Please scan a QR code or select your location from the menu");
    }
  };

  diningQuickPillClickHandler = (e) => {
    if (this.currentFromIndicator) {
      let closestNodes = this.map.getNodesClosestToPoint(
        this.currentFromIndicator.X,
        this.currentFromIndicator.Y,
        true
      );
      let closestNodesofInterest = this.filterNodeArrayByKeywords(
        closestNodes,
        ["dining", "cafeteria", "restaurant"]
      );

      if (closestNodesofInterest[0]) {
        document
          .querySelectorAll(
            '[data-id="' +
              closestNodesofInterest[0].ID +
              '"][class~="toSearchResult"]'
          )[0]
          .click();
      }
    } else {
      errorToast("Please scan a QR code or select your location from the menu");
    }
  };

  helpQuickPillClickHandler = (e) => {
    $("#helpModal").modal("show");
  };

  filterNodeArrayByKeywords(nodes, keywords) {
    return nodes.filter(
      (node) =>
        (node.Metadata.split(",").some((metadata) =>
          keywords.includes(metadata)
        ) ||
          keywords.some((keyword) =>
            node.Name.toLowerCase().includes(keyword)
          )) &&
        node.ID != this.currentFromIndicator.ID
    );
  }

  findPathHandler = (e) => {
    if (!this.fromValue || !this.toValue || this.fromValue == this.toValue)
      return false;

    let avoidances = 0;

    if (this.avoidStairs.checked) {
      avoidances = 1;
    } else if (this.avoidElevators.checked) {
      avoidances = 2;
    }

    startLoading();

    var req = {
      start: this.fromValue,
      end: this.toValue,
      avoidances: avoidances,
      nodes: this.map.nodes,
      floors: this.map.floors.map((floor) => {
        return {
          _id: floor._id,
          FloorIndex: floor.FloorIndex,
          FullName: floor.FullName,
        };
      }),
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req),
    };

    fetch("/api/getPath", requestOptions)
      .then(function (response) {
        if (response.status == 200) {
          return response.json();
        } else {
          throw response.json;
        }
      })
      .then(
        function (data) {
          let sortedPathNodes = data.pathNodes;

          this.currentPathNodes = sortedPathNodes;

          //get current floor's nodes
          let pathNodesThisFloor = sortedPathNodes.filter(
            (node) => node.FloorId == this.map.currentFloor._id
          );

          //draw path
          this.drawPath(pathNodesThisFloor, sortedPathNodes);

          //zoom to fit path
          this.map.zoomToFitNodes(pathNodesThisFloor);

          //show text directions
          this.setDirections(data.directionsHtml);

          //set cookie
          this.setCookie(
            "navpath",
            this.fromValue + "|" + this.toValue + "|" + this.map.siteId,
            1
          );

          endLoading();
        }.bind(this)
      )
      .catch(function (err) {
        console.error(err);
        endLoading();
      });
  };

  resetMap() {
    //clear directions
    this.directionsText.innerHTML = this.blankDirectionsText;

    //remove existing paths on canvas
    let oldpath = document.getElementsByClassName("path");
    while (oldpath.length > 0) {
      oldpath[0].parentNode.removeChild(oldpath[0]);
    }

    //remove any old intermediary indicators (elevators/stairs)
    let oldIndicators = document.getElementsByClassName("nodeIndicator");
    while (oldIndicators.length > 0)
      oldIndicators[0].parentNode.removeChild(oldIndicators[0]);
  }

  redrawMapElements() {
    //remove elements
    this.resetMap();

    //draw from indicator
    if (
      this.map.locationId &&
      this.map.loadedFloor == String(this.map.currentFloor._id)
    )
      this.fromDropdownChangeHandler();

    //redraw path for current floor if exists
    let currentFloorNodes = this.currentPathNodes.filter(
      (node) => node.FloorId == this.map.currentFloor._id
    );
    if (currentFloorNodes.length > 0) {
      this.drawPath(currentFloorNodes, this.currentPathNodes);

      //zoom to nodes
      this.map.zoomToFitNodes(currentFloorNodes);
    }

    //redraw directions if exist
    if (this.currentPathDirections)
      this.directionsText.innerHTML = this.currentPathDirections;

    //redraw node indicators if exist
    if (this.currentFromIndicator)
      this.drawFromIndicator(this.currentFromIndicator);
    if (this.currentToIndicator) this.drawToIndicator(this.currentToIndicator);
  }

  drawPath(thisFloorNodes, allFloorNodes) {
    this.resetMap();

    //start and end for this floor
    let floorStartNode = thisFloorNodes[0];
    let floorEndNode = thisFloorNodes[thisFloorNodes.length - 1];

    //start and end for whole path
    let pathStartNode = allFloorNodes[0];
    let pathEndNode = allFloorNodes[allFloorNodes.length - 1];

    //check if only one non-end node on this floor, ie elevator going up/down multiple floors
    if (thisFloorNodes.length == 1 && floorStartNode != pathEndNode) {
      this.drawIndicatorAtNode(thisFloorNodes[0]);
    } else {
      let pathText = "M";

      thisFloorNodes.forEach(function (value, index) {
        if (index == 0) pathText += value.X + " " + value.Y;
        else pathText += " L" + value.X + " " + value.Y;
      });

      document.getElementById("svg").innerHTML +=
        '<path class="path" d="' +
        pathText +
        '" stroke="red" stroke-width="5" fill="none" stroke-linejoin="round" </>';
    }

    //check if changing elevation at the end of this floor's path
    if (floorEndNode.IsElevator || floorEndNode.IsStair) {
      this.drawIndicatorAtNode(floorEndNode);
    }
  }

  setDirections(directionsHtml) {
    //reset existing directions text
    this.directionsText.innerHTML = "";
    this.directionsText.innerHTML = directionsHtml;
    this.currentPathDirections = directionsHtml;
  }

  fromDropdownChangeHandler = (e) => {
    this.fromValue = document.getElementById("fromDropdown").value;
    let selectedNode = this.map.nodes.find((node) => node.ID == this.fromValue);

    if (!selectedNode) {
      this.resetMap();
      return false;
    }

    if (selectedNode.FloorId != this.map.currentFloor._id)
      this.changeFloorToNode(selectedNode);

    this.drawFromIndicator(selectedNode);
    this.map.zoomToFitNodes([selectedNode]);
    this.startCompass();

    this.currentPathNodes = [];
    this.currentPathDirections = "";

    if (this.currentToIndicator) this.findPathHandler();

    document.getElementById("toText").focus();
  };

  toDropdownChangeHandler = (e) => {
    this.toValue = document.getElementById("toDropdown").value;
    let selectedNode = this.map.nodes.find((node) => node.ID == this.toValue);

    if (!selectedNode) {
      this.resetMap();
      return false;
    }

    this.drawToIndicator(selectedNode);
    this.startCompass();

    if (this.fromValue && this.toValue) this.findPathHandler();
  };

  changeFloorToNode(node) {
    if (node.FloorId == this.map.currentFloor._id) return false;

    let targetFloor = this.map.floors.find(
      (floor) => floor._id == node.FloorId
    );

    if (!targetFloor) return false;

    this.map.currentFloor = targetFloor;
    document.getElementById("svgdiv").innerHTML = targetFloor.SVG.replace(
      "{ImageBase64}",
      targetFloor.ImageBase64
    );
    this.map.svgdiv = document.getElementById("svgdiv");
    this.map.svg = document.getElementById("svg");
    //redraw all elements (indicators, paths, directions)
    this.redrawMapElements();
  }

  drawFromIndicator(node) {
    if (this.currentFromIndicator) this.removeFromIndicator();

    this.currentFromIndicator = node;

    if (node.FloorId != this.map.currentFloor._id) return false;

    if (this.map.isMobile) {
      document.getElementById("svg").innerHTML +=
        '<svg style="overflow:visible !important;" x="' +
        (node.X - 10) +
        '" y="' +
        (node.Y - 15) +
        '" ><polygon style="transform-origin: 10px 15px;" class="fromIndicator" id="fromIndicatorArrow" points="10,0 20,30 10,20 0,30"/></svg>';
    } else {
      document.getElementById("svg").innerHTML +=
        '<circle class="fromIndicator" cx="' +
        node.X +
        '" cy="' +
        node.Y +
        '" r="10"/>';
    }
  }

  drawToIndicator(node) {
    if (this.currentToIndicator) this.removeToIndicator();

    this.currentToIndicator = node;

    if (node.FloorId != this.map.currentFloor._id) return false;

    document.getElementById("svg").innerHTML +=
      '<image class="toIndicator" x="' +
      (node.X - 15) +
      '" y="' +
      (node.Y - 30) +
      '" width="30" height="30" xlink:href="/icons/location-dot.svg"/>';
  }

  drawIndicatorAtNode(node) {
    let nodeIndex = this.currentPathNodes.findIndex((n) => n.ID == node.ID);
    let thisFloorIndex = this.map.getFloorIndex(node);

    let nextNode = this.currentPathNodes[nodeIndex + 1];
    let nextFloorIndex = this.map.getFloorIndex(nextNode);

    if (nextFloorIndex > thisFloorIndex) {
      document.getElementById("svg").innerHTML +=
        '<image class="nodeIndicator bounce-up" x="' +
        (node.X - 15) +
        '" y="' +
        (node.Y - 30) +
        '" width="30" height="30" xlink:href="/icons/arrow-up.svg"/>';
    } else {
      document.getElementById("svg").innerHTML +=
        '<image class="nodeIndicator bounce-down" x="' +
        (node.X - 15) +
        '" y="' +
        (node.Y - 30) +
        '" width="30" height="30" xlink:href="/icons/arrow-down.svg"/>';
    }
  }

  removeFromIndicator() {
    this.currentFromIndicator = null;

    let circle = document.getElementsByClassName("fromIndicator");
    while (circle.length > 0) {
      circle[0].parentNode.removeChild(circle[0]);
    }
  }

  removeToIndicator() {
    this.currentToIndicator = null;

    let circle = document.getElementsByClassName("toIndicator");

    while (circle.length > 0) {
      circle[0].parentNode.removeChild(circle[0]);
    }
  }

  shortestPathChangeHandler = (e) => {
    this.setCookie("avoidances", "0", 1);
    if (this.fromValue && this.toValue) {
      this.findPathHandler();
    }
  };

  avoidStairsChangeHandler = (e) => {
    this.setCookie("avoidances", "1", 1);
    if (this.fromValue && this.toValue) {
      this.findPathHandler();
    }
  };

  avoidElevatorsChangeHandler = (e) => {
    this.setCookie("avoidances", "2", 1);
    if (this.fromValue && this.toValue) {
      this.findPathHandler();
    }
  };

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  startCompass() {
    if (this.map.isMobile) {
      if (typeof DeviceOrientationEvent.requestPermission === "function") {
        DeviceOrientationEvent.requestPermission()
          .then((permissionState) => {
            if (permissionState === "granted") {
              window.addEventListener(
                "deviceorientation",
                this.iOS13CompassHandler,
                true
              );
            } else {
              console.log("User denied location permissions");
            }
          })
          .catch(() => {
            console.log("Unable to get location permissions");
          });
      } else {
        // handle regular non iOS 13+ devices
        if (this.map.isIOS) {
          window.addEventListener("deviceorientation", this.compassHandler);
        } else if (this.map.isAndroid) {
          window.addEventListener(
            "deviceorientationabsolute",
            this.compassHandler,
            true
          );
        }
      }
    } else {
      window.addEventListener(
        "deviceorientationabsolute",
        this.compassHandler,
        true
      );
    }
  }

  iOS13CompassHandler = (e) => {
    this.compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
    let arrow = document.getElementById("fromIndicatorArrow");
    arrow.style.transform =
      "rotate(" +
      (parseInt(this.compass) + parseInt(this.compassOffsetDegrees)) +
      "deg)";
  };

  compassHandler = (e) => {
    if (e.webkitCompassHeading) {
      this.compass = e.webkitCompassHeading + 180;
    } else {
      this.compass = Math.abs(e.alpha - 360);
    }

    let arrow = document.getElementById("fromIndicatorArrow");

    if (arrow)
      arrow.style.transform =
        "rotate(" +
        (parseInt(this.compass) + parseInt(this.compassOffsetDegrees)) +
        "deg)";
  };
}
