import { EventEmitter } from "events";

export default class SVGMap extends EventEmitter {
  constructor(nodes, floors) {
    super();

    this.scale = 1.0;
    this.startX1;
    this.startX2;
    this.startY1;
    this.startY2;
    this.prevStartX1;
    this.prevStartY1;
    this.currentDoubleTouchScale = 1;
    this.previousDoubleTouchScale = 1;

    // does not work on iOS 13
    this.isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    this.isAndroid = /Android/i.test(navigator.userAgent);
    
    // doesn't work on iOS 13
    this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    //pageload vars
    this.toDropdown = document.getElementById("toDropdown");
    this.toText = document.getElementById("toText");

    this.loadedFloor = document.getElementById("floorId").value;
    this.locationId = document.getElementById("locationId").value;
    this.siteId = document.getElementById("siteId").value;
    this.nodes = nodes;
    this.floors = floors;
    this.currentFloor = floors.find(
      (floor) => String(floor._id) == this.loadedFloor
    );

    //svg
    this.svg = document.getElementById("svg");
    this.svgdiv = document.getElementById("svgdiv");

    let box = this.svg.getAttribute("viewBox").split(/\s+|,/);
    this.svgWidth = box[2];
    this.svgHeight = box[3];

    //map controls
    this.btnChangeFloorUp = document.getElementById("btnChangeFloorUp");
    this.btnChangeFloorDown = document.getElementById("btnChangeFloorDown");
    this.btnZoomOut = document.getElementById("btnZoomOut");
    this.btnZoomIn = document.getElementById("btnZoomIn");

    this.init();
  }

  // On page load
  init() {
    //reset scale
    this.scale = 1.0;

    //remove event listeners
    this.removeEvents();

    //add event listeners
    this.addEvents();
  }

  // Events
  addEvents() {
    this.addSVGEvents();
    this.addButtonEvents();
  }

  removeEvents() {
    this.removeSVGEvents();
    this.removeButtonEvents();
  }

  removeSVGEvents() {
    if (this.isMobile) {
      this.svgdiv.removeEventListener("touchstart", this.startTouch);
    } else {
      this.svgdiv.removeEventListener("mousewheel", this.mouseWheel);
      this.svgdiv.removeEventListener("mousedown", this.mouseDown);
    }
  }

  addSVGEvents() {
    if (this.isMobile) {
      this.svgdiv.addEventListener("touchstart", this.startTouch);
    } else {
      this.svgdiv.addEventListener("mousewheel", this.mouseWheel);
      this.svgdiv.addEventListener("mousedown", this.mouseDown);
    }
  }

  addButtonEvents() {
    this.btnChangeFloorUp.addEventListener("click", this.changeFloorUp);
    this.btnChangeFloorDown.addEventListener("click", this.changeFloorDown);
    this.btnZoomIn.addEventListener("click", this.zoomIn);
    this.btnZoomOut.addEventListener("click", this.zoomOut);
  }

  removeButtonEvents() {
    this.btnChangeFloorUp.removeEventListener("click", this.changeFloorUp);
    this.btnChangeFloorDown.removeEventListener("click", this.changeFloorDown);
    this.btnZoomIn.addEventListener("click", this.zoomIn);
    this.btnZoomOut.addEventListener("click", this.zoomOut);
  }

  //
  // Methods
  //

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }


  changeFloorUp = (e) => {
    let nextFloorUp = this.floors.find(
      (floor) => floor.FloorIndex == this.currentFloor.FloorIndex + 1
    );

    if (!nextFloorUp) return false;

    startLoading();
    this.currentFloor = nextFloorUp;
    document.getElementById("svgdiv").innerHTML = nextFloorUp.SVG.replace(
      "{ImageBase64}",
      nextFloorUp.ImageBase64
    );
    this.svgdiv = document.getElementById("svgdiv");
    this.svg = document.getElementById("svg");
    let box = this.svg.getAttribute("viewBox").split(/\s+|,/);
    this.svgWidth = box[2];
    this.svgHeight = box[3];

    this.emit("changeFloorUp", this.currentFloor);
    endLoading();
    this.init();
  };

  changeFloorDown = (e) => {
    let nextFloorDown = this.floors.find(
      (floor) => floor.FloorIndex == this.currentFloor.FloorIndex - 1
    );

    if (!nextFloorDown) return false;

    startLoading();
    this.currentFloor = nextFloorDown;
    document.getElementById("svgdiv").innerHTML = nextFloorDown.SVG.replace(
      "{ImageBase64}",
      nextFloorDown.ImageBase64
    );
    this.svgdiv = document.getElementById("svgdiv");
    this.svg = document.getElementById("svg");
    let box = this.svg.getAttribute("viewBox").split(/\s+|,/);
    this.svgWidth = box[2];
    this.svgHeight = box[3];

    this.emit("changeFloorDown", this.currentFloor);
    endLoading();
    this.init();
  };

  zoomIn = (e) => {
    let newScale = this.scale * 1.5;
    this.zoomByScale(newScale);
  };

  zoomOut = (e) => {
    let newScale = this.scale / 1.5;
    this.zoomByScale(newScale);
  };

  // Mouse Events Start
  mouseDown = (e) => {
    this.emit("mouseDown", e);

    this.prevStartX1 = e.clientX;
    this.prevStartY1 = e.clientY;

    this.startX1 = e.clientX;
    this.startY1 = e.clientY;

    this.svgdiv.addEventListener("mousemove", this.mouseMove);
    this.svgdiv.addEventListener("mouseup", this.mouseUp);
  };

  mouseMove = (e) => {
    const dx = parseInt((e.clientX - this.startX1) / this.scale);
    const dy = parseInt((e.clientY - this.startY1) / this.scale);

    this.startX1 = e.clientX;
    this.startY1 = e.clientY;

    this.panXY(dx, dy);
  };

  mouseUp = (e) => {
    let mouseMoved =
      this.prevStartX1 != this.startX1 || this.prevStartY1 != this.startY1;
    if (!mouseMoved && !e.target.getAttribute("data-nodeid")) {
      //advise listeners that mouse has clicked (without moving) an empty point on the map
      this.emit("mouseClickedEmptySpaceWithoutMove", e);
    }

    this.svgdiv.removeEventListener("mousemove", this.mouseMove);
    this.svgdiv.removeEventListener("mouseup", this.mouseUp);

    this.touchSelectDestination(e.clientX, e.clientY);
  };

  //
  // If selection a closes node will be found and the destination will be populated accordingly updating the map
  //
  touchSelectDestination = (newX, newY) => {
   
    // The SVG Point
    const pt = this.svg.createSVGPoint();

    // pass event coordinates
    pt.x = newX;
    pt.y = newY;

    // transform to SVG coordinates
    const svgP = pt.matrixTransform(svg.getScreenCTM().inverse());
    
    // if on mobile device, need to adjust for scale
    if ( this.isAndroid || this.isIOS) {
      svgP.x = svgP.x / this.scale;
      svgP.y = svgP.y / this.scale;
    }
    
    let x = parseInt(svgP.x);
    let y = parseInt(svgP.y);

    let closestNodes = this.getNodesClosestToPoint(x, y, true);

    // show selection, need to set the destination
    if (
      closestNodes.length > 0 &&
      this.currentFloor.FullName + " | " + closestNodes[0].Name != toText.value
    ) {
      // Confirm selection of new destination

      //var rect = this.svg.getBoundingClientRect();

      bootbox.confirm( 
       // "(left=" + rect.x  + " : top=" + rect.y + " :" + "width=" + (rect.width) + " : height=" + (rect.height) + ")  " +
       // "(xr=" + newX + " : yr=" + newY + " :" + "xt=" + x + " : yt=" + y + " : scale=" + this.scale + ")  " +
        "Are you sure you want change your destination to <strong>" +
          this.currentFloor.FullName +
          " | " +
          closestNodes[0].Name +
          "</strong>?",
        function (result) {
          if (result) {
            toDropdown.value = closestNodes[0].ID;
            toText.value =
              this.currentFloor.FullName + " | " + closestNodes[0].Name;

            var event = new Event("change");
            toDropdown.dispatchEvent(event);
          } else {
            // do nothing
          }
        }.bind(this)
      );
    }
  };

  getNodesClosestToPoint(x, y, destinationsOnly) {
    // Try to find closes destination node on a current level that user may wanted to select
    var floorId = this.currentFloor._id;
    var currentFloorNodes = this.nodes.filter(function (n) {
      return (
        n.FloorId == floorId &&
        (destinationsOnly ? n.Destination == true : true)
      );
    });

    // Calculate distance between the touch point and all the nodes on the current level
    currentFloorNodes.forEach(function (node) {
      node.touchDistance = Math.sqrt(
        Math.pow(Number(node.X) - Number(x), 2) +
          Math.pow(Number(node.Y) - Number(y), 2)
      );
    });

    // sort ascending
    return currentFloorNodes.sort((a, b) => a.touchDistance - b.touchDistance); // b - a for reverse sort
  }

  mouseWheel = (e) => {
    var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
    var newScale = this.scale + delta * 0.1;

    this.zoomByScale(newScale);

    e.preventDefault();

    return false;
  };
  // Mouse Events Stop

  // Gesture events start
  startTouch = (e) => {
    //console.log("event handler startTouch")

    const { touches } = e;

    if (touches && touches.length === 2) {
      this.startX1 = touches[0].clientX;
      this.startY1 = touches[0].clientY;
      this.startX2 = touches[1].clientX;
      this.startY2 = touches[1].clientY;

      this.svgdiv.addEventListener("touchmove", this.moveDoubleTouch);
      this.svgdiv.addEventListener("touchend", this.endDoubleTouch);
    } else if (touches && touches.length === 1) {
      this.prevStartX1 = touches[0].clientX;
      this.prevStartY1 = touches[0].clientY;
      this.startX1 = touches[0].clientX;
      this.startY1 = touches[0].clientY;

      this.svgdiv.addEventListener("touchmove", this.moveTouch);
      this.svgdiv.addEventListener("touchend", this.endTouch);
    }

    e.preventDefault();

    return false;
  };

  moveDoubleTouch = (e) => {

    // console.log("event handler moveDoubleTouch")

    let initialXDiff = Math.abs(this.startX1 - this.startX2);
    let initialYDiff = Math.abs(this.startY1 - this.startY2);
    let initialDistance = Math.hypot(initialXDiff, initialYDiff);

   
    let currentX1 = e.touches[0].clientX;
    let currentY1 = e.touches[0].clientY;

    let currentX2 = e.touches[1].clientX;
    let currentY2 = e.touches[1].clientY;

    let xDiff = currentX1 - currentX2;
    let yDiff = currentY1 - currentY2;

    let finalDistance = Math.hypot(xDiff, yDiff);

    let scaleX = finalDistance / initialDistance;

    this.currentDoubleTouchScale = this.previousDoubleTouchScale * scaleX;

    this.zoomByScale(this.currentDoubleTouchScale);
  };

  moveTouch = (e) => {
   // console.log("event handler moveTouch")

    const dx = parseInt((e.touches[0].clientX - this.startX1) / this.scale);
    const dy = parseInt((e.touches[0].clientY - this.startY1) / this.scale);

    this.startX1 = e.touches[0].clientX;
    this.startY1 = e.touches[0].clientY;

    this.panXY(dx, dy);
  };

  endDoubleTouch = (e) => {
    console.log("event handler endDoubleTouch")
    this.svgdiv.removeEventListener("touchmove", this.moveDoubleTouch);
    this.svgdiv.removeEventListener("touchend", this.endDoubleTouch);

    this.previousDoubleTouchScale = this.scale;
  };

  endTouch = (e) => {
   // console.log("event handler endTouch")

    this.svgdiv.removeEventListener("touchmove", this.moveTouch);
    this.svgdiv.removeEventListener("touchend", this.endTouch);


     // used to allow for small movement and still considering it a single selective touch
     var move_error_margin = 20;

     // Calculate the error in X and Y then sum it together
     var x_error = Math.abs(parseInt(this.prevStartX1) - parseInt( this.startX1));
     var y_error = Math.abs(parseInt(this.prevStartY1) - parseInt( this.startY1));
     var error_value = parseInt(x_error) + parseInt(y_error);
 
     // If error less than allowed error margin assume selective click
     //var isClickSelect = false;
 
     //TODO If user tried to pan and moved the screen a bit abort adding prompt to confirm selection of new desitination may be a good idea
     if (error_value < move_error_margin) {
      this.touchSelectDestination(this.prevStartX1, this.prevStartY1);
     }
  };
  // Gesture events end

  // Image manipulation auxiliary start
  getTransformParameters = (element) => {
    const transform = element.style.transform;
    let scale = 1,
      x = 0,
      y = 0;

    if (transform.includes("scale"))
      scale = parseFloat(transform.slice(transform.indexOf("scale") + 6));
    if (transform.includes("translateX"))
      x = parseInt(transform.slice(transform.indexOf("translateX") + 11));
    if (transform.includes("translateY"))
      y = parseInt(transform.slice(transform.indexOf("translateY") + 11));

    return { scale, x, y };
  };

  getTransformString = (scale, x, y) => {
    return (
      "scale(" +
      scale +
      ") " +
      "translateX(" +
      x +
      "px) translateY(" +
      y +
      "px)"
    );
  };

  panXY = (dx, dy) => {
    const { scale, x, y } = this.getTransformParameters(this.svg);
    this.svg.style.transform = this.getTransformString(scale, x + dx, y + dy);
  };

  zoomByScale = (scaleNew) => {
    if (scaleNew < 1) scaleNew = 1;
    if (scaleNew > 5) scaleNew = 5;

    this.scale = scaleNew;

    //Temporarily add transition effect
    this.svg.style.transition = "all .2s ease-in-out";

    const { scale, x, y } = this.getTransformParameters(this.svg);
    this.svg.style.transform = this.getTransformString(scaleNew, x, y);

    //Remove transition effect
    setTimeout(() => {
      this.svg.style.transition = "";
    }, 200);
  };
  // Image manipulation auxiliary end

  getFloorIndex = (node) => {
    return this.floors.find((floor) => floor._id == node.FloorId).FloorIndex;
  };

  zoomToFitNodes = (nodes) => {
    if (!nodes) return;

    //Temporarily add transition effect
    this.svg.style.transition = "all .2s ease-in-out";

    let firstNode = nodes[0];
    let scale = 1;
    let perX = 0;
    let perY = 0;

    //determine viewbox size and "middle" of map
    let svg = document.querySelector("svg");
    let box = svg.getAttribute("viewBox").split(/\s+|,/);
    let width = box[2];
    let height = box[3];

    //zoom in 2x scale if only one node on floor
    if (nodes.length == 1) {
      perX = (firstNode.X / width) * 100 * -1 + 50;
      perY = (firstNode.Y / height) * 100 * -1 + 50;

      scale = 2;
    }
    //determine middle point of first and last node on this floor, zoom max zoom-in 3x, min zoom-out 1x
    else {
      let lastNode = nodes[nodes.length - 1];

      //get middle of x/y coordinates of start/end nodes
      let midX = (firstNode.X + lastNode.X) / 2;
      let midY = (firstNode.Y + lastNode.Y) / 2;

      //get percentage of coord vs viewbox, plus some extra jank to offset for middle
      perX = (midX / width) * 100 * -1 + 50;
      perY = (midY / height) * 100 * -1 + 50;

      //scale to % of width or height, whichever is bigger??
      let xDiff = Math.abs(firstNode.X - lastNode.X);
      let yDIff = Math.abs(firstNode.Y - lastNode.Y);
      let perHeight = 1 / (xDiff / width);
      let perWidth = 1 / (yDIff / height);

      scale = perHeight < perWidth ? perHeight : perWidth;

      scale = scale < 1 ? 1 : scale;
      scale = scale > 3 ? 2 : scale;
    }

    this.scale = scale;
    let transformString =
      "scale(" +
      scale +
      ") " +
      "translateX(" +
      perX +
      "%) translateY(" +
      perY +
      "%)";
    this.svg.style.transform = transformString;

    //Store new XY offset
    //this.startX1 = e.clientX;
    //this.startY1 = e.clientY;

    //Remove transition effect
    setTimeout(() => {
      this.svg.style.transition = "";
    }, 200);
  };
}
