export default class PasswordResetForm {
    constructor(form) {

       this.form = document.querySelector("#passwordreset-form");

       this.allFields = document.querySelectorAll("#passwordreset-form .form-control");
       this.insertvalidationElements();

       this.password1 = document.querySelector("#password-reset");
       this.password1.previousValue = "";

       this.password2 = document.querySelector("#password-reset2");
       this.password2.previousValue = "";

       this.password1.isUnique = false;

       this.submitButton = document.querySelector("#passwordreset-form button[type='submit']");
       this.submitButton.isDisabled = true;

       this.events();
    }

    //Events
    events() {
        this.form.addEventListener("submit", e => {
            e.preventDefault();
            this.formSubmitHandler();
        });

        this.password1.addEventListener("keyup", () => {
            this.isDifferent(this.password1, this.passwordHandler);
        })

        this.password2.addEventListener("keyup", () => {
            this.isDifferent(this.password2, this.passwordHandler);
        })

        // On blur events prevent quick transitions to next field using TAB without triggering validation
        this.password1.addEventListener("blur", () => {
            this.isDifferent(this.password1, this.passwordHandler);
        })

        this.password2.addEventListener("blur", () => {
            this.isDifferent(this.password2, this.passwordHandler);
        })
    }

    // Methods
    formSubmitHandler() {
        this.passwordImmediately(this.password1);
        this.passwordAfterDelay(this.password2);

        if (this.password1.value !="" && !this.password1.errors) {
            this.form.submit();
        }
    }

    //Shared validator Methods
    isDifferent(el, handler) {
        if (el.previousValue != el.value) {
            handler.call(this, el);
        }
        el.previousValue = el.value;
    }

    hideValidationError(el) {
        el.nextElementSibling.classList.remove("liveValidateMessage--visible");
        this.submitButton.classList.remove("disabled");
    }

    showValidationError(el, message) {
        el.nextElementSibling.innerHTML = message;
        el.nextElementSibling.classList.add("liveValidateMessage--visible");
        el.errors = true;
        this.submitButton.classList.add("disabled");
    }

    insertvalidationElements() {
        this.allFields.forEach(function (el) {
            el.insertAdjacentHTML("afterend", '<div class="alert alert-danger small liveValidateMessage"></div>');
        });
    }

    passwordHandler(el) {
        el.errors = false;
        this.passwordImmediately(el);
        clearTimeout(el.timer)
        el.timer = setTimeout(() => this.passwordAfterDelay(el), 800);
    }

    passwordImmediately(el) {
        if (el.value != "" && !/^.{12,30}$/.test(el.value)) {
            this.showValidationError(el, "Password must be between 12 and 30 characters");
            el.errors = true;
        }

        if (el.value.length > 30) {
            this.showValidationError(el, "Password cannot exceed 30 characters");
            el.errors = true;
        }

        if (!el.errors) {
            this.hideValidationError(el);
        }
    }

    // Specific validator Methods
    passwordAfterDelay(el) {
        if (this.password1.value != "" && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])(?=.*?[!#%@#$%^&*()_+}:;'?<>.,{])[A-Za-z0-9!#%]{8,32}$/.test(el.value)) {
            this.showValidationError(el, "Please include upper and lower case letter, number(s), and special character(s)");
            this.password1.errors = true;
        } else {
            this.password1.errors = false;
        }
        
        if (this.password1.value != this.password2.value) {
            this.showValidationError(this.password2, "Passwords must match");
            this.password1.errors = true;
        } else {
            this.password1.errors = false;
        }
    }
   
}